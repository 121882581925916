<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-3">
        What is/are the main purpose(s) of keeping a lab notebook? Select ALL that apply.
      </p>

      <div v-for="option in options" :key="option.value" class="mb-0 pl-6">
        <v-checkbox
          v-model="inputs.inputs"
          class="d-inline-block my-0 py-1"
          :value="option.value"
          :dense="true"
          hide-details="true"
          :ripple="false"
        >
          <template #label>
            <stemble-latex :content="option.text" />
          </template>
        </v-checkbox>
      </div>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex.vue';

export default {
  name: 'uci51LCPrelab1Q4',
  components: {StembleLatex},
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        inputs: [],
      },
      options: [
        {
          text: 'Write down each step of the experiment with plenty of detail.',
          value: 'option1',
        },
        {
          text: 'Record ALL observations from the experiment.',
          value: 'option2',
        },
        {
          text: 'The notebook should only highlight the main points of the experiment.',
          value: 'option3',
        },
        {
          text: 'The lab notebook should only contain enough detail for you, and you alone, to be able to replicate the experiment. Secrecy is important.',
          value: 'option4',
        },
        {
          text: 'Anyone should be able to come in and replicate the experiment without any additional information.',
          value: 'option5',
        },
      ],
    };
  },
};
</script>
